<template>
  <v-row dense>
    <v-col cols="12">
      <v-card class="my-1">
        <v-card-title>
          <h5>Reclamações Abertas</h5>
        </v-card-title>
        <v-card-text>
          <v-radio-group 
            v-model="visaoGrafico" 
            row
            class="mb-0 ml-2"
          >
            <v-radio
              label="Mensal"
              value="mensal"
            />
            <v-radio
              label="Acumulado"
              value="acumulado"
            />
          </v-radio-group>
          <highcharts 
            :options=chartOptions 
            :deepCopyOnUpdate="false" 
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "DashboardFaturamentoVisaoAneelCardReclamacoesAbertas",
  props: {
    dados: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartOptions: {},
    visaoGrafico: "mensal",
  }),
  mounted() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      this.chartOptions = {
        chart: {
          type: 'area',
          zoomType: 'x',
          height: 400,
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: true },
        tooltip: { 
          shared: true,
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
          },
          area: {
            stacking: 'normal',
          }
        },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: 'Competência'
          }
        },
        yAxis: {
          title: { text: null },
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: this.getChartSeries(),
        exporting: this.exportingOptions,

      }; 
    },
    getChartSeries() {
      const seriesInfo = [
        { title: "Tarifa" , varName: "recl_tarifa"},
        { title: "Fatura" , varName: "recl_fatura"},
        { title: "Variação de Consumo" , varName: "variacao_consumo"},
        { title: "Erro de Leitura" , varName: "recl_erro_leitura"},
        { title: "Custo de Disponibilidade" , varName: "custo_disponibilidade"},
        { title: "Cobrança indevida de acessória" , varName: "cobranca_indevida"}
      ];

      return this.visaoGrafico === "mensal" 
        ? this.getChartSeriesMensal(seriesInfo)
        : this.getChartSeriesAcumulado(seriesInfo); 
    },
    getChartSeriesMensal(seriesInfo) {
      return seriesInfo
        .map(serie => {
          return {
            name: serie.title,
            animation: false,
            data: this.categories
              .map(category => {
                return category in this.dados 
                  ? { x: this.categories.indexOf(category), y: Number(this.dados[category][serie.varName]) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              .filter(val => val !== null && val.y !== null && val.y > 0)
          }
        });
    },
    getChartSeriesAcumulado(seriesInfo) {
      return seriesInfo.map(serie => {
          let acumulado = 0;

          return {
            name: serie.title,
            animation: false,
            data: this.categories.map(category => {
                if (category in this.dados) {
                  acumulado += Number(this.dados[category][serie.varName]);

                  return { 
                    x: this.categories.indexOf(category), 
                    y: acumulado
                  };
                }
                else {
                  return { 
                    x: this.categories.indexOf(category), 
                    y: null 
                  };
                }
              })
              .filter(val => val !== null && val.y !== null && val.y > 0)
          }
        });
    },
  },
  watch: {
    dados() {
      this.setChartOptions();
    },
    visaoGrafico() {
      this.setChartOptions();
    }
  }
}

</script>

<style>

</style>